import { gql } from '@apollo/client';

export const EVENT_OPTIONS_QUERY = gql`
  query EventOptionsQuery {
    landmarks {
      id
      name
      timezoneId
    }
    metaTags(depth: 2, type: event) {
      id: path
      name: displayName
    }
  }
`;

export const TAGS_QUERY = gql`
  query TagsQuery(
    $landmarkId: NullUUID
  ) {
    tags(
      landmarkId: $landmarkId,
      includeUnassociated: true
    ) {
      id
      name
    }
  }
`;
